import React from "react";
import { Form, Input, message, Button, Upload } from "antd";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { reject } from "lodash";
import * as XLSX from "xlsx";

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

class PushNotificationsFormBulk extends React.Component {
  state = {
    listData: [],
  };

  excelData = [];

  reset = () => {
    this.props.form.resetFields();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formValue;

    this.props.form.validateFieldsAndScroll((error, value) => {
      formValue = value;
      if (error) {
        message.error("Validation error, Please check your field");
      } else {
        // this.props.onSubmit(formValue, this.reset);
        // console.log(formValue);
      }
    });
    formValue.usersId = this.excelData;
    this.props.onSubmit(formValue, this.reset);
  };

  readExcel = (file) => {
    const promis = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = (e) => {
        const bufferArray = e.target.result;
        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_json(ws);
        resolve(data);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
    promis.then((d) => {
      d.forEach((row) => {
        this.excelData.push(row.id);
      });
    });
  };

  dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <h1>Bulk Notification</h1>
        <FormItem {...formItemLayout} label="title">
          {getFieldDecorator("title", {
            rules: [
              {},
              {
                required: true,
                message: "Please input a title!",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Message">
          {getFieldDecorator("message", {
            rules: [
              {},
              {
                required: true,
                message: "Please input a message!",
              },
            ],
          })(<TextArea />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Excel">
          <Upload
            customRequest={this.dummyRequest}
            beforeUpload={(file) => {
              if (file) {
                this.readExcel(file);
              } else {
                message.error("Error while uploading excel file!");
              }
            }}
          >
            <Button>Click to Upload</Button>
          </Upload>
          <small>
            *you can upload multiple files but be aware of duplicates.
          </small>
        </FormItem>

        <FormItem {...tailFormItemLayout}>
          <Button loading={this.props.loading} type="primary" htmlType="submit">
            Send
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const PushNotificationsSendFormBulk = Form.create()(PushNotificationsFormBulk);
export default PushNotificationsSendFormBulk;
