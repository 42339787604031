import { Table, message, Popconfirm, Divider, Icon, Select } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
// import axios from 'axios';
import Moment from "react-moment";
import moment from "moment";
import _ from "lodash";
import API, { getAxiosInstance } from "../../api/api";
import { ChangeUserPasswordModal } from "./changePassword";

let TableInstance = null;

function onUnverify(v) {
  // console.log(TableInstance);
  const hide = message.loading("Action in progress..", 0);
  getAxiosInstance()
    .post(API.verify_user, { userId: v.id, verified: false })
    .then((res) => {
      hide();
      if (res.data.status) {
        message.success("User Verified");
        TableInstance.fetch(TableInstance.state.lastRequest);
      } else {
        message.error(`Error |  ${res.data.dialog.message}`);
      }
    })
    .catch((err) => {
      hide();
      console.error(err);
      message.error("Oops, something went wrong");
    });
}
function onConfirm(v) {
  // console.log(TableInstance);
  const hide = message.loading("Action in progress..", 0);
  getAxiosInstance()
    .post(API.verify_user, { userId: v.id, verified: true })
    .then((res) => {
      hide();
      if (res.data.status) {
        message.success("User Verified");
        TableInstance.fetch(TableInstance.state.lastRequest);
      } else {
        message.error(`Error |  ${res.data.dialog.message}`);
      }
    })
    .catch((err) => {
      hide();
      console.error(err);
      message.error("Oops, something went wrong");
    });
}

function cancel(e) {}

const columns = [
  {
    title: "ID",
    dataIndex: "id",
    defaultSortOrder: "ascend",
    sorter: (a, b) => a.id - b.id,
    key: "id",
  },
  {
    title: "Name",
    dataIndex: "firstName",

    key: "firstName",
    render: (data, doc) => (
      <span>
        {doc.firstName} {doc.lastName}
      </span>
    ),
  },
  {
    title: "Email",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Phone",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Has Submited Document",
    dataIndex: "hasSubmited",
    // sorter: true,
    filters: [{ text: "Yes", value: true }, { text: "No", value: false }],
    onFilter: (value, record) => record.hasSubmited === value,
    filterMultiple: false,
    key: "hasSubmited",
    render: (data) => <span>{data ? "Yes" : "No"}</span>,
  },
  {
    title: "Documents",
    dataIndex: "document",
    width: 200,
    key: "document",
    render: (data) =>
      data
        ? data.map((d, index) => (
            <span key={d.id}>
              <a rel="noopener noreferrer" target="_blank" href={d.url}>
                {index + 1}
              </a>{" "}
              <Divider type="vertical" />{" "}
            </span>
          ))
        : null,
  },
  {
    title: "Verified",
    dataIndex: "verified",
    // sorter: true,
    filterMultiple: false,
    filters: [{ text: "Yes", value: true }, { text: "No", value: false }],
    onFilter: (value, record) => record.verified === value,
    key: "verified",
    render: (data) => <span>{data ? "Yes" : "No"}</span>,
  },

  {
    title: "Created At",
    sorter: true,
    dataIndex: "dateCreation",
    key: "dateCreation",
    render: (date) => (
      <span>
        {date ? (
          <Moment
            title={moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            fromNow
          >
            {date}
          </Moment>
        ) : (
          "-"
        )}
      </span>
    ),
  },
  {
    title: "Action",
    key: "action",
    width: 220,
    render: (text, record) => (
      <span>
        {!(record && record.verified) ? (
          <span>
            <Popconfirm
              title="Are you sure verify this user?"
              onConfirm={() => onConfirm(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a>Verify</a>
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Popconfirm
              title="Are you sure Un-verify this user?"
              onConfirm={() => onUnverify(record)}
              onCancel={cancel}
              okText="Yes"
              cancelText="No"
            >
              <a>Un Verify</a>
            </Popconfirm>
          </span>
        )}

        <Divider type={"vertical"} />
        <ChangeUserPasswordModal userId={record.id} />
      </span>
    ),
  },
];

class UsersList extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 5 },
    lastRequest: {},
    loading: false,
    options: [],
  };

  constructor(props) {
    super(props);
    TableInstance = this;
  }

  componentDidMount() {
    this.fetch({});
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      order: sorter.field,
      ascending: !(sorter.order === "descend"),
      currentPage: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
    });
  };

  fetch = ({
    pageSize = this.state.pagination.pageSize,
    currentPage = 0,
    order = null,
    ascending = true,
    filters = [],
  }) => {
    this.setState({ loading: true });
    const mappedFilters = {};
    _.each(filters, (value, key) => {
      mappedFilters[key] = value[0];
      // mappedFilters.push(toReturn);
    });
    const lastRequest = {
      pageSize,
      currentPage,
      order,
      ascending,
      filters,
    };
    this.setState({ lastRequest });
    const request = {
      page: currentPage - 1,
      size: pageSize,
      order,
      ascending,
      ...mappedFilters,
    };

    getAxiosInstance()
      .post(API.users_table, request)
      .then((response) => {
        const pagination = { ...this.state.pagination };
        // console.log(response);
        pagination.total = response.data.data.total;
        this.setState({
          options: response.data.data.list,
          data: response.data.data.list,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  handlePaginationChange = (event) => {
    console.log(event.target.value);
    const pager = { ...this.state.pagination };
    pager.pageSize = parseInt(event.target.value);
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pager.pageSize,
    });
  };

  searchUsers = (e) => {
    let searchField = e.target.id;
    var target = e.target.value;
    var suggestions = this.state.options;
    var match = [];
    console.log(suggestions);
    switch (searchField) {
      case "search_id":
        suggestions.forEach((user) => {
          if (`${user.id}`.includes(`${target}`)) {
            match.push(user);
          }
        });
        break;
      case "search_name":
        suggestions.forEach((user) => {
          if (user.firstName.toLowerCase().includes(target.toLowerCase())) {
            match.push(user);
          }
        });
        break;
      case "search_email":
        suggestions.forEach((user) => {
          if (user.email.toLowerCase().includes(target.toLowerCase())) {
            match.push(user);
          }
        });
        break;
      case "search_phone":
        suggestions.forEach((user) => {
          if (user.phone.includes(target)) {
            match.push(user);
          }
        });
        break;

      default:
        this.setState({ data: suggestions });
        break;
    }

    this.setState({
      data: match,
    });
  };

  render() {
    const { loading, data, pagination } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Users List</title>
        </Helmet>
        <form onSubmit={this.handlePaginationChange}>
          <label>
            Nb of records per page:
            <select
              value={this.state.value}
              onChange={this.handlePaginationChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="99999">All</option>
            </select>
          </label>
        </form>
        {/* <label for="search_id">
          Search by ID:
          <input type="text" onChange={this.searchUsers} id="search_id" />
        </label>
        &nbsp; &nbsp;
        <label for="search_name">
          Search by name:
          <input type="text" onChange={this.searchUsers} id="search_name" />
        </label>
        &nbsp; &nbsp;
        <label for="search_email">
          Search by email:
          <input type="text" onChange={this.searchUsers} id="search_email" />
        </label>
        &nbsp; &nbsp;
        <label for="search_phone">
          Search by phone:
          <input type="text" onChange={this.searchUsers} id="search_phone" />
        </label> */}
        &nbsp;
        <Table
          rowKey={(record) => record.id}
          loading={loading}
          dataSource={data}
          pagination={pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
      </React.Fragment>
    );
  }
}

export default UsersList;
