import {
  Form,
  Input,
  Icon,
  Select,
  Checkbox,
  Button,
  message,
  Card,
  Divider
} from "antd";
import React from "react";
import _ from "lodash";
import { Helmet } from "react-helmet";
import { navigate } from "@reach/router";
import ChapterFormContainer from "./chaptersForm";
import API, { getAxiosInstance } from "../../api/api";

const FormItem = Form.Item;
const { Option } = Select;
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}

class CreateBook extends React.Component {
  state = {
    chapters: [],
    loadingButton: false,
    loading: false,
    uploading: [],
    authors: [],
    listData: [],
    dirName: guid()
  };

  references = [];

  // componentDidUpdate=() => {
  //   this.references = [];
  // }

  componentWillMount = () => {
    this.setState({ loading: true });
    getAxiosInstance()
      .post(API.getCategories)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.list
        ) {
          this.setState({ listData: response.data.data.list, loading: false });
          // this.setState({  });
        }
      })
      .catch(err => {
        message.error("something went wrong");
        this.setState({ loading: false });
      });
  };

  handleSubmit = (publish = false) => {
    // e.preventDefault();
    const chapters = [];
    let errExist = false;
    let anyUpload = false;

    _.each(this.references, ref => {
      if (ref.ref && ref.ref.props.uploading) {
        anyUpload = true;
      }
    });
    if (anyUpload) {
      return message.error("please wait for uploads to finish");
    }
    _.each(this.references, ref => {
      // console.log(ref);
      if (ref.ref) {
        ref.ref.validateFields((err, values) => {
          if (!err) {
            chapters.push(values);
          }
          if (err) {
            errExist = true;
          }
        });
      }
    });
    let formValue;
    this.props.form.validateFieldsAndScroll((error, value) => {
      formValue = value;
      if (error) {
        errExist = true;
      }
    });
    if (!errExist) {
      formValue.chapters = _.filter(chapters, chp => !!chp);
      formValue.chapters = formValue.chapters.map((chapter, index) => {
        const clone = chapter;
        clone.orderIndex = index;
        return clone;
      });
      // formValue.authors = [formValue.authors];
      formValue.active = publish;
      return this.submitData(formValue);
    }
    return message.error("Validation error, Please check your field");
  };

  submitData = formValue => {
    this.setState({ loadingButton: true });
    // console.log(formValue);
    getAxiosInstance()
      .post(API.create_book, formValue)
      .then(response => {
        if (response.data.status) {
          this.setState({ loadingButton: false });
          navigate("/admin/books-active");
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
        }
      })
      .catch(err => {
        console.error(err);
        message.error("Oops, something went wrong :( ");
        this.setState({ loadingButton: false });
      });
  };

  onAddChapter = () => {
    this.setState(prevState => {
      const array = prevState.chapters;

      prevState.chapters.push({
        _id: _.uniqueId()
      });
      return { chapters: array };
    });
  };

  onRemoveChapter = id => {
    this.references = _.reject(this.references, v => {
      console.log(v.id, id);
      return v.id === id;
    });
    // console.log(_.reject(this.references,v=>{
    //   console.log(v.id,id,v);
    //   return v.id===id
    // }));
    console.log(this.references);
    // console.log(this.references);
    this.setState(prev => {
      const newChildren = _.filter(prev.chapters, cur => cur._id !== id);
      return {
        chapters: newChildren
      };
    });
  };
  addReference = ref => {
    console.log("adding", ref);
    this.references.push(ref);
  };

  toggleUploading = (id, state) => {
    if (state) {
      this.setState(prev => {
        prev.uploading.push(id);
        return {
          uploading: prev.uploading
        };
      });
    } else {
      this.setState(prev => ({
        uploading: _.filter(prev.uploading, v => v !== id)
      }));
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 }
      }
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 4
        }
      }
    };

    return (
      <Card loading={this.state.loading}>
        <Form onSubmit={this.handleSubmit}>
          <Helmet>
            <title>Create Book</title>
          </Helmet>

          <FormItem {...formItemLayout} label="Title">
            {getFieldDecorator("title", {
              rules: [
                {},
                {
                  required: true,
                  message: "Please input a title!"
                }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Reader Name">
            {getFieldDecorator("readerName", {
              rules: [
                {},
                {
                  required: true,
                  message: "Chose A Reader Name"
                }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem {...formItemLayout} label="Reader Gender">
            {getFieldDecorator("readerGender", {
              rules: [
                {},
                {
                  required: true,
                  message: "Chose Reader's gender"
                }
              ]
            })(
              <Select style={{ width: "100%" }} placeholder="Gender">
                <Option value="male">Male</Option>
                <Option value="female">Female</Option>
              </Select>
            )}
          </FormItem>
          <FormItem {...formItemLayout} label="Author">
            {getFieldDecorator("authors", {
              rules: [
                {
                  required: true,
                  message: "Please input an author!",
                  type: "array"
                }
              ]
            })(
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="Write Multiple Authors"
                // onChange={this.onChangeAuthors}
              >
                {this.state.authors}
              </Select>
            )}
          </FormItem>
          <FormItem

            {...formItemLayout}
            label="Description"
          >
            {getFieldDecorator('description', {
              rules: [{

              }],
            })(
              <Input.TextArea rows={4} />,
            )}
          </FormItem>

          <FormItem {...formItemLayout} label="Categories">
            {getFieldDecorator("categoriesIds", {
              rules: [
                {
                  required: true
                }
              ]
            })(
              <Select
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                mode="multiple"
                style={{ width: "100%" }}
                placeholder="Please select"
              >
                {this.state.listData.map(v => (
                  <Option key={v.id}>{v.name}</Option>
                ))}
              </Select>
            )}
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            {getFieldDecorator("free", {
              valuePropName: "checked"
            })(<Checkbox>Free Book</Checkbox>)}
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            {getFieldDecorator("blind", {
              valuePropName: "checked"
            })(<Checkbox>Only for blind</Checkbox>)}
          </FormItem>

          {this.state.chapters.map((v, key) => (
            <FormItem key={v._id} {...tailFormItemLayout}>
              <ChapterFormContainer
                dirName={this.state.dirName}
                toggleUploading={this.toggleUploading}
                uploading={_.find(this.state.uploading, f => f === v._id)}
                id={v._id}
                onRemove={this.onRemoveChapter}
                onAdd={this.addReference}
                // ref={(ref) => {
                //   console.log("adding",v._id,ref);
                //   if (!_.find(this.references, r => r.id === v._id)) { this.references.push({ ref, id: v._id }); }
                // }}
                key={key}
              />
            </FormItem>
          ))}
          <FormItem {...tailFormItemLayout}>
            <Button
              type="dashed"
              onClick={this.onAddChapter}
              style={{ width: "60%" }}
            >
              <Icon type="plus" />
              Add Chapter
            </Button>
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            <Button
              loading={this.state.loadingButton}
              type="primary"
              onClick={() => {
                this.handleSubmit();
              }}
            >
              Save
            </Button>
            <Divider type="vertical" />
            <Button
              loading={this.state.loadingButton}
              type="primary"
              onClick={() => {
                this.handleSubmit(true);
              }}
            >
              Save And Publish
            </Button>
          </FormItem>
        </Form>
      </Card>
    );
  }
}
const Wrapper = Form.create()(CreateBook);
export default Wrapper;
