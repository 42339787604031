import React from 'react';
import { Modal, Button, Input, message,Icon } from 'antd';
import { getAxiosInstance } from '../../api/api';
import API from '../../api/api';

export class ChangeUserPasswordModal extends React.Component {
  state = { visible: false };

  showModal = () => {
    this.setState({
      visible: true,
      loading:false,
    });
  };

  handleOk = (e) => {
    if (!this.state.password || this.state.password === '') {
      message.error('Please enter a password');
      return;
    }
    getAxiosInstance()
      .post(API.change_password, {
        userId: this.props.userId,
        password: this.state.password
      })
      .then((res) => {
        this.setState({ loading: true });
        if (res.data.status) {
          message.success('User Password Changed');
          this.setState({visible:false})

        } else {
          message.error(`Error |  ${res.data.dialog.message}`);
        }
      })
      .catch((err) => {

        message.error('Oops, something went wrong');
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  };

  handleCancel = (e) => {
    console.log(e);
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <span>
        <a type="primary" onClick={this.showModal}>
          Change Password
        </a>
        <Modal
          closable={!this.state.loading}
          confirmLoading={this.state.loading}
          title="Basic Modal"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Input onChange={e => {
            this.setState({ password: e.target.value });
          }} prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }}/>}/>
        </Modal>
      </span>
    );
  }
}
