import React from "react";
import { Form, Checkbox, Select, Input, message, Button } from "antd";
import FormItem from "antd/lib/form/FormItem";
import API, { getAxiosInstance } from "../../api/api";

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};

class BookForm extends React.Component {
  state = {
    listData: [],
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let formValue;
    this.props.form.validateFieldsAndScroll((error, value) => {
      formValue = value;
      if (error) {
        message.error("Validation error, Please check your field");
      } else {
        this.props.onSubmit(formValue);
      }
    });
  };

  componentWillMount = () => {
    this.setState({ loading: true });
    getAxiosInstance()
      .post(API.getCategories)
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.list
        ) {
          this.setState({ listData: response.data.data.list, loading: false });
          // this.setState({  });
        }
      })
      .catch((err) => {
        message.error("something went wrong");
        this.setState({ loading: false });
      });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.handleSubmit}>
        <FormItem {...formItemLayout} label="Title">
          {getFieldDecorator("title", {
            initialValue: this.props.data.title,
            rules: [
              {},
              {
                required: true,
                message: "Please input a title!",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Reader Name">
          {getFieldDecorator("readerName", {
            initialValue: this.props.data.readerName,
            rules: [
              {},
              {
                message: "Chose A Reader Name",
              },
            ],
          })(<Input />)}
        </FormItem>
        <FormItem {...formItemLayout} label="Reader Gender">
          {getFieldDecorator("readerGender", {
            initialValue: this.props.data.readerGender,
            rules: [
              {},
              {
                message: "Chose Reader's gender",
              },
            ],
          })(
            <Select style={{ width: "100%" }} placeholder="Gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Author">
          {getFieldDecorator("authors", {
            initialValue: this.props.data.authors,
            rules: [
              {
                required: true,
                message: "Please input an author!",
                type: "array",
              },
            ],
          })(
            <Select
              mode="tags"
              style={{ width: "100%" }}
              placeholder="Write Multiple Authors"
            >
              {this.state.authors}
            </Select>
          )}
        </FormItem>
        <FormItem {...formItemLayout} label="Description">
          {getFieldDecorator("description", {
            initialValue: this.props.data.description,
            rules: [{}],
          })(<Input.TextArea rows={4} />)}
        </FormItem>

        <FormItem {...formItemLayout} label="Categories">
          {getFieldDecorator("categoriesIds", {
            initialValue: this.props.data.categories
              ? this.props.data.categories.map((cat) => cat.id.toString())
              : null,
            rules: [
              {
                required: true,
              },
            ],
          })(
            <Select
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              mode="multiple"
              style={{ width: "100%" }}
              placeholder="Please select"
            >
              {this.state.listData.map((v) => (
                <Select.Option key={v.id}>{v.name}</Select.Option>
              ))}
            </Select>
          )}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          {getFieldDecorator("blind", {
            initialValue: this.props.data.tailFormItemLayout,
            valuePropName: "checked",
          })(<Checkbox>Only for blind</Checkbox>)}
        </FormItem>

        <FormItem {...tailFormItemLayout}>
          {getFieldDecorator("free", {
            initialValue: this.props.data.tailFormItemLayout,
            valuePropName: "checked",
          })(<Checkbox>Free Book</Checkbox>)}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          {getFieldDecorator("active", {
            initialValue: this.props.data.active,
            valuePropName: "checked",
          })(<Checkbox>Active</Checkbox>)}
        </FormItem>
        <FormItem {...tailFormItemLayout}>
          <Button loading={this.props.loading} type="primary" htmlType="submit">
            Update
          </Button>
        </FormItem>
      </Form>
    );
  }
}

const BooksForm = Form.create()(BookForm);
export default BooksForm;
