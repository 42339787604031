import React from 'react';
import S3Client from 'aws-s3';

import {
  Form, Icon, Input, Button, Card, Upload, Spin, message, Divider,
} from 'antd';
import API, { getAxiosInstance } from '../../api/api';
import { S3Upload } from '../../helpers/s3Uploader';

const config = {
  bucketName: 'lib-books-app-new',
  // dirName: 'uploaded-audio',

  region: 'us-east-1',
  accessKeyId: 'AKIAIT6U6RHRRPP7NY7Q',
  secretAccessKey: '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',
};
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return `${s4() + s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
}
const FormItem = Form.Item;
class SimpleChapterForm extends React.Component {
    dirName=guid();

    state={
      uploadedFile: null,
      loading: false,
    }

    handleSubmit=(e) => {
      e.preventDefault();
      this.setState({ loading: true });
      this.props.form.validateFieldsAndScroll((error, value) => {
        console.log(this.state.uploadedFile);
        if (!error) {
          if (!this.state.uploadedFile) {
            this.setState({ loading: false });
            return message.error('Please add a file');
          }

          const data = { ...value, mp3Url: this.state.uploadedFile.location, bookId: this.props.book.id };
          this.addChapter(data);
        }
      });
    }

    addChapter=(chapterData) => {
      getAxiosInstance().post(API.addChapterToBook, chapterData).then((response) => {
        console.log(response);
        if (response.data.status) {
          this.props.closeModal();
          message.success('Chapter Added');
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
        }
        this.setState({ loading: false });
      }).catch((err) => {
        console.log(err);
        this.setState({ loading: false });
        message.error('Oops, something went wrong :( ');
      });
    }

    render() {
    // TODO: Fix directory name
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit}>
          <FormItem>
            {getFieldDecorator('title', {
              rules: [{ required: true, message: 'title is required!' }],
            })(
              <Input placeholder="Title" />,
            )}
          </FormItem>
          {/* <FormItem>
            {getFieldDecorator('description', {
              rules: [{  message: 'description is required!' }],
            })(
              <Input placeholder="Description" />,
            )}
          </FormItem> */}
          <FormItem

            label="Upload Audio"
          >
            {getFieldDecorator('mp3Url', {
              rules: [{ required: false }],
            })(
              <Input placeholder="audio" hidden />,
            )}
            {
          this.state.uploadedFile
            ? <a target="_blank" href={this.state.uploadedFile.location}>Audio</a>
            : (
              <Upload
                fileList={this.state.fileList}
                defaultFileList={this.state.fileList}
                onChange={this.onFileChange}
                showUploadList={false}
                beforeUpload={(file, fileList) => {
                  // console.log(file);
                  // console.log(fileList);
                  // if(!this.props.bookname)
                  // {
                  //   message.error('You need to enter the book name before uploading');
                  //   return ;
                  // }
                  this.setState({ loading: true });
                  S3Upload(file, this.dirName).then((data) => {
                    this.setState({ loading: false });
                    this.setState({
                      uploadedFile: data,
                    });
                  }).catch((err) => {
                    console.error(err);
                    this.setState({ loading: false });
                  });
                  return false;
                }}
                accept="audio/*"
                name="logo"
              >
                {
            this.state.uploading ? <Spin /> : (
              <Button>
                <Icon type="upload" />
                {' '}
Click to upload
              </Button>
            )
          }
                {/* {this.props.form.getFieldError('file')&&<p style={{color:'red'}}>You need to upload a file</p>} */}
              </Upload>
            )

        }

          </FormItem>
          <FormItem>
            <Button loading={this.state.loading} type="primary" htmlType="submit">Save</Button>
            <Divider type="vertical" />
            <Button onClick={this.props.handleClose} disabled={this.state.loading} type="danger">Close</Button>
          </FormItem>

        </Form>
      );
    }
}


export const ChapterFormSimple = Form.create()(SimpleChapterForm);
