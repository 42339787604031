import { Table, Tag, message } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
import Moment from "react-moment";
import moment from "moment";
import { Link } from "@reach/router";
import API, { getAxiosInstance } from "../../api/api";

const columns = [
  {
    title: "Title",
    dataIndex: "title",
    sorter: true,
    key: "title",
    render: (title, record) => (
      <Link to={`/admin/book/${record.id}`}>{title}</Link>
    ),
  },
  {
    title: "Author",
    // sorter: true,
    dataIndex: "authors",
    key: "authors",
  },
  {
    title: "Description",
    dataIndex: "description",
    width: 250,
    key: "description",
  },
  {
    title: "Categories",
    key: "categories",
    width: 100,
    dataIndex: "categories",
    render: (categories) => (
      <span>
        {categories &&
          categories.map((cat) => (
            <Tag color="blue" key={cat.id}>
              {cat.name}
            </Tag>
          ))}
      </span>
    ),
  },
  {
    title: "Created At",
    sorter: true,
    dataIndex: "dateCreation",
    key: "dateCreation",
    render: (date) => (
      <span>
        {date ? (
          <Moment
            title={moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            fromNow
          >
            {date}
          </Moment>
        ) : (
          "-"
        )}
      </span>
    ),
  },
];

class BooksList extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 5 },
    loading: false,
  };

  componentDidMount() {
    this.fetch({});
  }

  componentDidUpdate = ({ active }) => {
    if (active !== this.props.active) {
      this.fetch({});
    }
  };

  fetch = ({
    pageSize = this.state.pagination.pageSize,
    currentPage = 0,
    order = null,
    ascending = true,
  }) => {
    this.setState({ loading: true });
    getAxiosInstance()
      .post(API.books_table, {
        page: currentPage - 1,
        size: pageSize,
        order,
        ascending,
        active: this.props.active,
      })
      .then((response) => {
        const pagination = { ...this.state.pagination };
        pagination.total = response.data.data.total;
        console.log("list", response.data.data.list);
        this.setState({
          data: response.data.data.list,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong");
      });
  };

  handlePaginationChange = (event) => {
    console.log(event.target.value);
    const pager = { ...this.state.pagination };
    pager.pageSize = parseInt(event.target.value);
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pager.pageSize,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      order: sorter.field,
      ascending: !(sorter.order === "descend"),
      currentPage: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      ...filters,
    });
  };

  render() {
    const { loading, data, pagination } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>Books List</title>
        </Helmet>
        <form onSubmit={this.handlePaginationChange}>
          <label>
            Nb of records per page:
            <select
              value={this.state.value}
              onChange={this.handlePaginationChange}
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="99999">All</option>
            </select>
          </label>
        </form>
        <Table
          // rowKey={(record) => record.id}
          loading={loading}
          dataSource={data}
          pagination={pagination}
          onChange={this.handleTableChange}
          columns={columns}
        />
        {/* // dataSource={data} */}
      </React.Fragment>
    );
  }
}

export default BooksList;
