import React from 'react';
import { message } from 'antd';
import { navigate, Link } from '@reach/router';
import CategoriesForm from './form';
import API, { getAxiosInstance } from '../../api/api';


export class UpdateCategory extends React.Component {
    state={
      loading: false,
      data: null,
    }

    componentDidMount = () => {
      const { categoryId } = this.props;
      getAxiosInstance().post(API.getSingleCategory, { id: categoryId }).then((response) => {
        if (response.data.status) {
          this.setState({ loading: false, data: response.data.data });
        } else {
          this.setState({ loading: false });
          message.error(response.data.dialog.message);
        }
      }).catch((err) => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
        this.setState({ loading: false });
      });
    }

    onSubmit=(formValue) => {
      const { categoryId } = this.props;
      this.setState({ loading: true });
      getAxiosInstance().post(API.update_category, { categoryId, ...formValue }).then((response) => {
        console.log(response);
        if (response.data.status) {
          this.setState({ loading: false });
          navigate('/admin/list-categories');
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
          this.setState({ loading: false });
        }
      }).catch((err) => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
        this.setState({ loading: false });
      });
    }

    render() {
      const { loading, data } = this.state;
      return (
       <>
         <Link to="/admin/list-categories">Back</Link>
         <CategoriesForm loading={loading} data={data} onSubmit={this.onSubmit} />
       </>
      );
    }
}
