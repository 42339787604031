import Axios from 'axios';

// const SANDBOX = 'http://54.152.201.199'
const SANDBOX = 'http://kitabi.tecfrac.com';
const PRODUCTION = 'https://kitabi.tecfrac.com';

const SERVER_ADDRESS = process.env.NODE_ENV === 'production' ? PRODUCTION : SANDBOX;
const API = {
  login: `${SERVER_ADDRESS}/books/auth/login/admin`,
  getCategories: `${SERVER_ADDRESS}/books/bookadmin/categories`,
  getSingleBook: `${SERVER_ADDRESS}/books/bookadmin/get/book`,
  addChapterToBook: `${SERVER_ADDRESS}/books/bookadmin//book/chapter/add`,
  orderChapters: `${SERVER_ADDRESS}/books/bookadmin/chapter/edit/order`,
  chapterEdit: `${SERVER_ADDRESS}/books/bookadmin/book/chapter/edit`,
  bookEdit: `${SERVER_ADDRESS}/books/bookadmin/book/edit`,
  create_book: `${SERVER_ADDRESS}/books/bookadmin/book/add`,
  books_table: `${SERVER_ADDRESS}/books/bookadmin/books/get/pages`,
  categories_table: `${SERVER_ADDRESS}/books/bookadmin/categories`,
  getSingleCategory: `${SERVER_ADDRESS}/books/bookadmin/category/id`,
  create_category: `${SERVER_ADDRESS}/books/bookadmin/category/add`,
  update_category: `${SERVER_ADDRESS}/books/bookadmin/category/edit`,
  users_table: `${SERVER_ADDRESS}/books/bookadmin/user/get/pages`,
  sessions_table: `${SERVER_ADDRESS}/books/bookadmin/session/get/pages`,
  verify_user: `${SERVER_ADDRESS}/books/bookadmin/user/verify`,
  unverify_user: `${SERVER_ADDRESS}/books/bookadmin/user/unverify`,
  create_push: `${SERVER_ADDRESS}/books/bookadmin/send/push`,
  create_push_specific_users: `${SERVER_ADDRESS}/books/bookadmin/send/push/users`, //bulk push notification
  change_password: `${SERVER_ADDRESS}/books/bookadmin/user/change/password`
};
// export var AxiosInstance = Axios.create({
//   // baseURL: 'https://some-domain.com/api/',
//   timeout: 1000,
//   headers: { token: localStorage.getItem('connectionSession') },
// });
export const getAxiosInstance = () => Axios.create({
  // baseURL: 'https://some-domain.com/api/',
  timeout: 60000,
  headers: {
    deviceId: localStorage.getItem('deviceId'),
    token: localStorage.getItem('connectionSession')
  },
});
export default API;