import React from 'react';
import {
  Form, Input, message, Button,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import TextArea from 'antd/lib/input/TextArea';


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};


class PushNotificationsForm extends React.Component {
    state = {
      listData: [],
    }

    reset=() => {
      this.props.form.resetFields();
    }

    handleSubmit = (e) => {
      e.preventDefault();
      let formValue;
      this.props.form.validateFieldsAndScroll((error, value) => {
        formValue = value;
        if (error) {
          message.error('Validation error, Please check your field');
        } else {
          this.props.onSubmit(formValue, this.reset);
          // console.log(formValue);
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}

            label="title"
          >
            {getFieldDecorator('title', {

              rules: [{

              }, {
                required: true, message: 'Please input a title!',
              }],
            })(
              <Input />,
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}

            label="Message"
          >
            {getFieldDecorator('message', {

              rules: [{

              }, {
                required: true, message: 'Please input a message!',
              }],
            })(
              <TextArea />,
            )}
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            <Button loading={this.props.loading} type="primary" htmlType="submit">Send</Button>
          </FormItem>
        </Form>
      );
    }
}


const PushNotificationsSendForm = Form.create()(PushNotificationsForm);
export default PushNotificationsSendForm;
