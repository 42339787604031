import { Table, message, Popconfirm, Divider } from "antd";
import React from "react";
import { Helmet } from "react-helmet";
// import axios from 'axios';
import Moment from "react-moment";
import moment from "moment";
import _ from "lodash";
import { Link } from "@reach/router";
import API, { getAxiosInstance } from "../../api/api";

const columns = [
  {
    title: "id",
    sorter: true,
    dataIndex: "id",
    key: "id",
    render: (id) => <Link to={`/admin/category/${id}`}>{id}</Link>,
  },
  {
    title: "name",
    sorter: true,
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Created At",
    sorter: true,
    dataIndex: "dateCreation",
    key: "dateCreation",
    render: (date) => (
      <span>
        {date ? (
          <Moment
            title={moment(date).format("MMMM Do YYYY, h:mm:ss a")}
            fromNow
          >
            {date}
          </Moment>
        ) : (
          "-"
        )}
      </span>
    ),
  },
];

class CategoriesList extends React.Component {
  state = {
    data: [],
    pagination: { pageSize: 5 },
    lastRequest: {},
    loading: false,
  };

  componentDidMount() {
    this.fetch({});
  }

  handleTableChange = (pagination, filters, sorter) => {
    const pager = { ...this.state.pagination };
    pager.current = pagination.current;
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pagination.pageSize,
      order: sorter.field,
      ascending: !(sorter.order === "descend"),
      currentPage: pagination.current,
      sortField: sorter.field,
      sortOrder: sorter.order,
      filters,
    });
  };

  fetch = ({
    pageSize = this.state.pagination.pageSize,
    currentPage = 0,
    order = null,
    ascending = true,
    filters = [],
  }) => {
    this.setState({ loading: true });
    const mappedFilters = {};
    _.each(filters, (value, key) => {
      mappedFilters[key] = value[0];
      // mappedFilters.push(toReturn);
    });
    const lastRequest = {
      pageSize,
      currentPage,
      order,
      ascending,
      filters,
    };
    this.setState({ lastRequest });
    const request = {
      page: currentPage - 1,
      size: pageSize,
      order,
      ascending,
      ...mappedFilters,
    };

    getAxiosInstance()
      .post(API.categories_table, request)
      .then((response) => {
        const pagination = { ...this.state.pagination };
        console.log(response);
        pagination.total = response.data.data.total;
        this.setState({
          data: response.data.data.list,
          loading: false,
          pagination,
        });
      })
      .catch((err) => {
        console.error(err);
        message.error("Something went wrong");
      });
  };
  handlePaginationChange = (event) => {
    console.log(event.target.value);
    const pager = { ...this.state.pagination };
    pager.pageSize = parseInt(event.target.value);
    this.setState({
      pagination: pager,
    });
    this.fetch({
      pageSize: pager.pageSize,
    });
  };

  render() {
    const { loading, data, pagination } = this.state;
    return <React.Fragment>
        <Helmet>
          <title>Books List</title>
        </Helmet>
        <form onSubmit={this.handlePaginationChange}>
          <label>
            Nb of records per page:
            <select value={this.state.value} onChange={this.handlePaginationChange}>
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="99999">All</option>
            </select>
          </label>
        </form>
        <Table loading={loading //   rowKey={record => record.id}
          } dataSource={data} pagination={pagination} onChange={this.handleTableChange} columns={columns} />
      </React.Fragment>;
  }
}

export default CategoriesList;
