import AWS from 'aws-sdk';

AWS.config.region = 'us-east-1'; // Region
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'us-east-1:ea8b6ef3-79ae-4712-8541-69a93189d2b2',
});
const bucket = new AWS.S3({
  params: {
    Bucket: 'lib-books-app-new',
  },
});
// lib-books-app-new
export const S3Upload = (file, directory) => {
  console.log(AWS.config.credentials.needsRefresh());
  const objKey = `${directory}/${file.name}`;
  const params = {
    Key: objKey,
    ContentType: file.type,
    Body: file,
    ACL: 'public-read',
  };

  return new Promise((resolve, reject) => {
    AWS.config.credentials.get(() => {
    //   if (err) alert(err);
      bucket.putObject(params, (err, data) => {
        if (err) {
          reject(err);
        } else {
          console.log(data);
          resolve({ location: `https://lib-books-app-new.s3.amazonaws.com/${directory}/${file.name}`, name: file.name });
        }
      });
    });
  });
};
