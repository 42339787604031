import {
  Layout, Menu, Icon, Dropdown, Row, Col, Avatar, Button,
} from 'antd';
import React from 'react';
import './layout.css';
import {
  Router, Link, Location, Redirect,
} from '@reach/router';
import SubMenu from 'antd/lib/menu/SubMenu';
import { Helmet } from 'react-helmet';
import BooksList from '../modules/books/list';
import BooksForm from '../modules/books/create';
import UsersList from '../modules/users/list';
import BookSingle from '../modules/books/bookSingle';
import SessionsList from '../modules/sessions/list';
import { NotFound } from './notFound';
import CategoriesList from '../modules/categories/list';
import { CreateCategory } from '../modules/categories/create';
import { UpdateCategory } from '../modules/categories/update';
import { CreatePushNotification } from '../modules/pushNotifications/create';

const {
  Header, Content, Footer, Sider,
} = Layout;

let AppRef = null;
const menu = (
  <Menu>
    <Menu.Item key="0">
      <Button onClick={() => {
        AppRef.props.logout();
      }}
      >  <Icon type="logout" /> Logout
      </Button>

    </Menu.Item>

  </Menu>
);
class Application extends React.Component {
    state = {
      collapsed: false,
    };

    onCollapse = (collapsed) => {
      this.setState({ collapsed });
    }

    componentDidMount=() => {
      AppRef = this;
    }

    render() {
      return (
<>
  <Helmet>
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  </Helmet>
  <Layout style={{ minHeight: '100vh' }}>

    <Location>
      {({ location }) => (
        <Sider
          collapsible
          collapsed={this.state.collapsed}
          onCollapse={this.onCollapse}
        >
          <div style={{ height: 32, marginBottom: 20, padding: 5 }}>
            <img style={{ width: '100%', height: 'auto' }} src="https://lib.kitabi.org/wp-content/uploads/2019/03/logo-1.png" alt="logo"/>
          </div>
          <Menu theme="dark" defaultSelectedKeys={['/']} selectedKeys={[location.pathname]} mode="inline">


            <SubMenu key="books_sub" title={<span><Icon type="book" /> <span>Books</span></span>}>
              <Menu.Item key="/admin/create-books">
                <Icon type="plus" /> <span>Create Book</span>
                <Link style={{ color: 'white' }} to="/admin/create-books" />
              </Menu.Item>
              <Menu.Item key="/admin/books-active">
                <Icon type="ordered-list" theme="outlined" /><span>Active Books</span>
                <Link to="/admin/books-active" />
              </Menu.Item>
              <Menu.Item key="/admin/books-inactive">
                <Icon type="ordered-list" theme="outlined" /><span>Inactive Books</span>
                <Link to="/admin/books-inactive" />
              </Menu.Item>
            </SubMenu>

            <SubMenu key="users_sub" title={<span><Icon type="team" /><span>Users</span></span>}>
              <Menu.Item key="/admin/list-users">
                <Icon type="user" theme="outlined" /><span>Users List</span>
                <Link to="/admin/list-users" />
              </Menu.Item>
              <Menu.Item key="/admin/list-guests">
                <Icon type="mobile" theme="outlined" /><span>Guest Users List</span>
                <Link to="/admin/list-guests" />
              </Menu.Item>
            </SubMenu>


            <SubMenu key="categories_sub" title={<span><Icon type="tags" /><span>Categories</span></span>}>
              <Menu.Item key="/admin/create-category">
                <Icon type="plus" /> <span>Create Category</span>
                <Link style={{ color: 'white' }} to="/admin/create-category" />
              </Menu.Item>
              <Menu.Item key="/admin/list-categories">
                <Icon type="ordered-list" theme="outlined" /><span>Categories List</span>
                <Link to="/admin/list-categories" />
              </Menu.Item>
            </SubMenu>


            <SubMenu key="notifications" title={<span><Icon type="bell" /><span>Push Notifications</span></span>}>
              <Menu.Item key="/admin/pushnotification">
                <Icon type="plus" theme="outlined" /><span>Send</span>
                <Link to="/admin/pushnotification" />
              </Menu.Item>
            </SubMenu>


          </Menu>
        </Sider>
      )}

    </Location>

    <Layout>
      <Header style={{ background: '#fff' }}>

        <Row>
          <Col span={20}>
            {/* <Link to="../">Back</Link> */}
          </Col>
          <Col span={4}>
            <Dropdown overlay={menu}>
              <Avatar style={{ backgroundColor: this.state.color, verticalAlign: 'middle' }} size="large">
                Test
              </Avatar>

            </Dropdown>
          </Col>
        </Row>
      </Header>
      <Content style={{ margin: '14px 16px' }}>
        <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
          <Router>
            <Redirect noThrow from="/admin" to="/admin/list-users" />
            <Redirect noThrow from="/" to="/admin/list-users" />
            <NotFound default />
            <BooksList active path="/admin/books-active" />
            <BooksList active={false} path="/admin/books-inactive" />
            <CreateCategory path="admin/create-category" />
            <UpdateCategory path="/admin/category/:categoryId" />
            <SessionsList path="/admin/list-guests" />
            <UsersList path="/admin/list-users" />
            <BooksForm path="/admin/create-books" />
            <BookSingle path="/admin/book/:bookId" />
            <CreatePushNotification path="/admin/pushnotification" />

            <CategoriesList path="/admin/list-categories" />
          </Router>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
          Tecfrac ©{new Date().getFullYear()} All rights reserved
      </Footer>
    </Layout>
  </Layout>
</>

      );
    }
}

export default Application;
