import React from 'react';

import {
  Form, Checkbox, Select, Input, message, Button,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import API, { getAxiosInstance } from '../../api/api';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 20,
      offset: 4,
    },
  },
};


class ChapterSimpleForm extends React.Component {
    handleSubmit = (e) => {
      e.preventDefault();
      let formValue;
      this.props.form.validateFieldsAndScroll((error, value) => {
        formValue = value;
        if (error) {
          message.error('Validation error, Please check your field');
        } else {
          this.props.onSubmit(formValue);
          // console.log(formValue);
        }
      });
    }

    render() {
      const { getFieldDecorator } = this.props.form;
      return (
        <Form onSubmit={this.handleSubmit}>
          <FormItem
            {...formItemLayout}

            label="name"
          >
            {getFieldDecorator('title', {
              initialValue: this.props.data && this.props.data.title,
              rules: [{

              }, {
                required: true, message: 'Please input a title!',
              }],
            })(
              <Input />,
            )}
          </FormItem>

          <FormItem {...tailFormItemLayout}>
            <Button loading={this.props.loading} type="primary" htmlType="submit">Save</Button>
          </FormItem>
        </Form>
      );
    }
}


export const ChapterFormSimple = Form.create()(ChapterSimpleForm);
