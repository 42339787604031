import React from 'react';
import { message } from 'antd';
import { navigate } from '@reach/router';
import CategoriesForm from './form';
import API, { getAxiosInstance } from '../../api/api';


export class CreateCategory extends React.Component {
    state={
      loading: false,
    }

    onSubmit=(formValue) => {
      this.setState({ loading: true });
      console.log(formValue);
      getAxiosInstance().post(API.create_category, formValue).then((response) => {
        console.log(response);
        if (response.data.status) {
          this.setState({ loading: false });
          navigate('/admin/list-categories');
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
          this.setState({ loading: false });
        }
      }).catch((err) => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
        this.setState({ loading: false });
      });
    }

    render() {
      const { loading } = this.state;
      return (
        <CategoriesForm loading={loading} onSubmit={this.onSubmit} />
      );
    }
}
