import Login from 'ant-design-pro/lib/Login';
import { Alert,message } from 'antd';
import React from 'react';
import './login.css';
import Axios from 'axios';
import API from '../api/api';

const {
  UserName, Password, Submit,
} = Login;

class LoginPage extends React.Component {
  state = {
    notice: '',
    type: 'tab2',
    loading: false,
  }

  onSubmit = (err, values) => {
    console.log('here');
    if (!err) {
      const { username, password } = values;
      this.setState({ loading: true });
      Axios.post(API.login, { username, password }).then((res) => {
        this.setState({ loading: false });
        res.status = (res.data.status === 'true');

        if (res.data.status === true) {
          localStorage.setItem('connectionSession', res.data.data.token);
          localStorage.setItem('deviceId', res.data.data.deviceId);
          this.props.onLogginVerified();
        } else {
          this.setState({ notice: 'Login failed, wrong username/password' });
        }
      }).catch(err=>{
        this.setState({ loading: false });
        console.error(err);
        message.error(err.stack);
      });

      // login here
    }
    //
  }


  render() {
    return (
      <div style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/login.jpg)` }} className="loginContainer">
        {/* <img src="/assets/images/bg1.jpg" /> */}
        <div className="mainLoginManagement">
          <Login
            ref={(form) => {
              this.loginForm = form;
            }}
            defaultActiveKey={this.state.type}
            onTabChange={this.onTabChange}
            onSubmit={this.onSubmit}
          >
            <p style={{ fontSize: '3em', color: 'white', textAlign: 'center' }}>LIB Books System</p>
            {
              this.state.notice
              && <Alert style={{ marginBottom: 24 }} onClose={() => { this.setState({ notice: null }); }} message={this.state.notice} type="error" showIcon closable />
            }
            <UserName name="username" />
            <Password onPressEnter={() => this.loginForm.validateFields(this.onSubmit)} name="password" />


            <Submit loading={this.state.loading}>Login</Submit>

          </Login>
        </div>
      </div>


    );
  }
}

export default LoginPage;
