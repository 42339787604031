import React from 'react';
import { Card, message, Row, Col, Button } from 'antd';
import { Tree } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import API, { getAxiosInstance } from '../../api/api';
import BooksForm from './bookForm';
import { EditChapter } from './editChapterName';
import { AddChapterModal } from './addChapterModal';
import _ from 'lodash';
// fake data generator
const getItems = count =>
  Array.from({ length: count }, (v, k) => k)
    .map(k => ({
      id: `item-${k}`,
      content: `item ${k}`
    }));

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'lightgreen' : 'white',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: '100%'
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  console.log(removed);
  result.splice(endIndex, 0, removed);

  return result;
};

class BookSingle extends React.Component {
  state = {
    items: getItems(10),
    loading: true,
    data: {
      chapters: []
    },
    chapters: []
  };

  onDownloadAll = () => {
    var chapters = _.cloneDeep(this.state.chapters)
    var interval = setInterval(download, 500,chapters);
    function download(chapters) {
      var chapter = chapters.pop()
      let iframe = document.createElement('iframe');
          iframe.style.visibility = 'collapse';
          document.body.append(iframe);
          iframe.contentDocument.write(
            `<form action="${chapter.mp3Url.replace(/\"/g, '"')}" method="GET"></form>`
          );
          iframe.contentDocument.forms[0].submit();
          setTimeout(() => iframe.remove(), 1000);
          if (chapters.length == 0) {
            clearInterval(interval);
          }
    }
  };

  onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.chapters,
      result.source.index,
      result.destination.index
    );

    this.setState({
      chapters: items
    });
  };

  fetchData = () => {
    const { bookId } = this.props;
    getAxiosInstance()
      .post(API.getSingleBook, { bookId })
      .then(response => {
        if (response.data.status) {
          this.setState({
            loading: false,
            data: response.data.data,
            chapters: response.data.data.chapters.sort(
              (a, b) => a.orderIndex - b.orderIndex
            )
          });
        } else {
          console.error(response.data);
          this.setState({ loading: false });
          message.error(response.data.dialog.message);
        }
      })
      .catch(err => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
        this.setState({ loading: false });
      });
  };

  componentDidMount = () => {
    this.fetchData();
  };

  onSaveOrder = () => {
    const { bookId } = this.props;
    const chapterIds = this.state.chapters.map(chp => chp.id);
    getAxiosInstance()
      .post(API.orderChapters, {
        bookId,
        chapterIds
      })
      .then(response => {
        if (response.data.status) {
          message.success('Saved Order !');
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
        }
      })
      .catch(err => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
      });
  };

  onSubmit = data => {
    console.log(data);
    const { bookId } = this.props;
    this.setState({ loadingUpdateOrder: true });
    getAxiosInstance()
      .post(API.bookEdit, { bookId, ...data })
      .then(response => {
        if (response.data.status) {
          this.setState({ loadingUpdateOrder: false });
          message.success(' Book Updated');
        } else {
          this.setState({ loadingUpdateOrder: false });
          console.error(response.data);
          message.error(response.data.dialog.message);
        }
      })
      .catch(err => {
        console.error(err);
        this.setState({ loadingUpdateOrder: false });
        message.error('Oops, something went wrong :( ');
      });
  };

  render() {
    return (
      <Card loading={this.state.loading}>
        <Row gutter={8}>
          <Col span={12}>
            <Card title="Edit Book">
              <BooksForm
                loading={this.state.loadingUpdateOrder}
                onSubmit={this.onSubmit}
                data={this.state.data}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card
              extra={
                <>
                  <Button onClick={this.onDownloadAll} type="primary">
                    Download All
                  </Button>
                  <AddChapterModal
                    refreshData={this.fetchData}
                    book={this.state.data}
                  />
                  <Button onClick={this.onSaveOrder} type="primary">
                    Save Order
                  </Button>
                </>
              }
              title="Re Order Chapters"
            >
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {this.state.chapters.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <span> {item.title}</span>{' '}
                              <div style={{ float: 'right' }}>
                                {' '}
                                <EditChapter
                                  refreshBookData={this.fetchData}
                                  data={item}
                                />{' '}
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Card>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default BookSingle;
