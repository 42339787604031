import React from 'react';
import { Modal, Button } from 'antd';
import { ChapterFormSimple } from './simpleChapterForm';

export class AddChapterModal extends React.Component {
  state = { visible: false, closable: true }

  showModal = () => {
    this.setState({
      visible: true,
    });
  }

  setClosable=(closable) => {
    this.setState({ closable: !!closable });
  }

  handleOk = (e) => {
    this.setState({
      visible: false,
    });
    this.props.refreshData();
  }

  handleClose = (e) => {
    this.setState({
      visible: false,
    });
  }

  render() {
    return (
      <>
        <Button icon="plus" onClick={this.showModal}>
          Add Chapter
        </Button>
        <Modal
          destroyOnClose
          footer={null}
          closable={false}
          title="Add Chapter"
          maskClosable={false}
          keyboard={false}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <ChapterFormSimple handleClose={this.handleClose} setClosable={this.setClosable} closeModal={this.handleOk} {...this.props} />
        </Modal>
      </>
    );
  }
}
