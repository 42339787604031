import {
  Table, message, Popconfirm, Divider,
} from 'antd';
import React from 'react';
import { Helmet } from 'react-helmet';
// import axios from 'axios';
import Moment from 'react-moment';
import moment from 'moment';
import _ from 'lodash';
import API, { getAxiosInstance } from '../../api/api';


let TableInstance = null;

const columns = [{
  title: 'id',
  sorter: true,
  dataIndex: 'id',
  key: 'id',
},
// {
//   title: 'Device Id',
//   sorter: true,
//   dataIndex: 'deviceId',
//   key: 'deviceId',
// },
// {
//   title: 'User Id',
//   sorter: true,
//   dataIndex: 'userId',
//   key: 'userId',
// },
{
  title: 'Token',
  dataIndex: 'token',
  key: 'token',
},
{
  title: 'OS',
  dataIndex: 'operationSystem',
  key: 'operationSystem',
},
{
  title: 'App Version',
  dataIndex: 'applicationVersion',
  key: 'applicationVersion',
},
{
  title: 'Is Valid?',
  dataIndex: 'valid',
  key: 'valid',
  render: valid => (
    <span>
      {valid ? 'Yes' : 'No'}
    </span>
  ),
},
{
  title: 'Last Used',
  sorter: true,
  dataIndex: 'lastAccess',
  key: 'lastAccess',
  render: date => (
    <span>
      {date ? <Moment title={moment(date).format('MMMM Do YYYY, h:mm:ss a')} fromNow>{date}</Moment> : '-'}
    </span>
  ),
},
{
  title: 'Created At',
  sorter: true,
  dataIndex: 'dateCreation',
  key: 'dateCreation',
  render: date => (
    <span>
      {date ? <Moment title={moment(date).format('MMMM Do YYYY, h:mm:ss a')} fromNow>{date}</Moment> : '-'}
    </span>
  ),
},
{
  title: 'Expired At',
  sorter: true,
  dataIndex: 'dateExpired',
  key: 'dateExpired',
  render: date => (
    <span>
      {date ? <Moment title={moment(date).format('MMMM Do YYYY, h:mm:ss a')} fromNow>{date}</Moment> : '-'}
    </span>
  ),
},
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     render: (text, record) => (
  //       !(record && record.verified)
  //       && (
  //       <span>
  //         <Popconfirm title="Are you sure verify this user?" onConfirm={() => onConfirm(record)} onCancel={cancel} okText="Yes" cancelText="No">
  //           <a>Verify</a>
  //         </Popconfirm>

//       </span>
//       )
//     ),
//   },
];


class SessionsList extends React.Component {
    state = {
      data: [],
      pagination: { pageSize: 1000 },
      lastRequest: {},
      loading: false,
    };

    constructor(props) {
      super(props);
      TableInstance = this;
    }


    componentDidMount() {
      this.fetch({});
    }

    handleTableChange = (pagination, filters, sorter) => {
      const pager = { ...this.state.pagination };
      pager.current = pagination.current;
      this.setState({
        pagination: pager,
      });
      this.fetch({
        pageSize: pagination.pageSize,
        order: sorter.field,
        ascending: !(sorter.order === 'descend'),
        currentPage: pagination.current,
        sortField: sorter.field,
        sortOrder: sorter.order,
        filters,
      });
    }

    fetch=({
      pageSize = 1000, currentPage = 0, order = null, ascending = true, filters = [],
    }) => {
      this.setState({ loading: true });
      const mappedFilters = {};
      _.each(filters, (value, key) => {
        mappedFilters[key] = value[0];
        // mappedFilters.push(toReturn);
      });
      const lastRequest = {
        pageSize, currentPage, order, ascending, filters,
      };
      this.setState({ lastRequest });
      const request = {
        page: currentPage - 1,
        size: pageSize,
        userId: 1,
        order,
        ascending,
        ...mappedFilters,


      };

      getAxiosInstance().post(API.sessions_table, request).then((response) => {
        const pagination = { ...this.state.pagination };
        console.log(response);
        pagination.total = response.data.data.total;
        this.setState({ data: response.data.data.list, loading: false, pagination });
      }).catch((err) => {
        console.error(err);
        message.error('Something went wrong');
      });
    }

    render() {
      const { loading, data, pagination } = this.state;
      return (
        <React.Fragment>
          <Helmet>
            <title>Guest Users List</title>
          </Helmet>
          <Table
        //   rowKey={record => record.id}
            loading={loading}
            dataSource={data}
            pagination={pagination}
            onChange={this.handleTableChange}
            columns={columns}
          />
        </React.Fragment>);
    }
}


export default SessionsList;
