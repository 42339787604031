import React from 'react';
import { Helmet } from 'react-helmet';
import './App.css';
import Application from './layout/layout';
import LoginPage from './login/login';


class App extends React.Component {
  state={
    loggedIn: false,
  }


  componentDidMount() {
    const sessionIdExist = localStorage.getItem('connectionSession');
    if (sessionIdExist) { this.setState({ loggedIn: true }); }
  }

  logout=() => {
    localStorage.removeItem('connectionSession');
    //add server logout here
    this.setState({ loggedIn: false });
  }

  onLogginVerified=() => {
    this.setState({ loggedIn: true });
  }

  render() {
    const { loggedIn } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Books Application</title>
        </Helmet>
        {
       loggedIn
         ? <Application logout={this.logout} />
         : <LoginPage onLogginVerified={this.onLogginVerified} />
     }
      </React.Fragment>
    );
  }
}
export default App;
