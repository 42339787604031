import React from 'react';
import { Button, Modal, message } from 'antd';
import { ChapterFormSimple } from './chapterSimpleForm';
import API, { getAxiosInstance } from '../../api/api';


export class EditChapter extends React.Component {
    state = { visible: false }

    showModal = () => {
      this.setState({
        visible: true,
        loading: false,
      });
    }

    handleOk = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }

    handleCancel = (e) => {
      console.log(e);
      this.setState({
        visible: false,
      });
    }

    onSubmit=(formValue) => {
      const { id, orderIndex } = this.props.data;
      this.setState({ loading: true });
      console.log(this.props.data);
      getAxiosInstance().post(API.chapterEdit, { chapterId: id, orderIndex, ...formValue }).then((response) => {
        console.log(response);
        if (response.data.status) {
          this.setState({ loading: false });
          message.success('saved');
          this.props.refreshBookData();
          this.handleOk();
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
          this.setState({ loading: false });
        }
      }).catch((err) => {
        console.error(err);
        message.error('Oops, something went wrong :( ');
        this.setState({ loading: false });
      });
    }

    render() {
      const { visible, loading } = this.state;
      return (
        <div>
          <Button type="primary" onClick={this.showModal}>
            Edit
          </Button>
          <Modal
            title="Basic Modal"
            footer={null}
            visible={visible}
            onOk={this.handleOk}
            onCancel={this.handleCancel}
          >
            <ChapterFormSimple onSubmit={this.onSubmit} loading={loading} data={this.props.data} />
          </Modal>
        </div>
      );
    }
}
