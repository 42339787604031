import React from 'react';
import S3Client from 'aws-s3';

import {
  Form, Icon, Input, Button, Card, Upload, Spin, message, Row, Col,
} from 'antd';
import { S3Upload } from '../../helpers/s3Uploader';

// const config = {
//   bucketName: 'lib-books-app-new',
//   // dirName: 'uploaded-audio',

//   region: 'us-east-1',
//   accessKeyId: 'AKIAIT6U6RHRRPP7NY7Q',
//   secretAccessKey: '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',
// };
const FormItem = Form.Item;

class ChapterForm extends React.Component {
  state = {
    uploadedFile: null,
  }

  componentDidMount=()=>{
    this.props.onAdd({ref:this,id:this.props.id})
  }

  onSubmit = () => {
    this.props.form.validateFieldsAndScroll((error, value) => {
      console.log(error, value);
    });
  }

  onFileChange = ({ fileList }) => {
    // if ( ... ) {
    //   ...
    // } else {
    //   ...
    // }
    // always setState
    console.log(fileList);
    this.setState({ fileList: [...fileList] });
  }
  // componentWillUnmount=()=>{
  //   this.props.onRemove(this.props.id);
  // }
  onClickRemove = () => {
    // console.log('click');
    this.props.onRemove(this.props.id);
  }
  validateFields=this.props.form.validateFields;

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Card bodyStyle={{ padding: 10 }}>
        <div onSubmit={this.handleSubmit} className="login-form">
          <Row gutter={12}>
            <Col span={12}>
              <FormItem>
                {getFieldDecorator('title', {
                  rules: [{ required: true, message: 'title is required!' }],
                })(
                  <Input placeholder="Title" />,
                )}
              </FormItem>
            </Col>
            <Col span={8}>
              <FormItem>
                {getFieldDecorator('mp3Url', {
                  rules: [{ required: true }],
                })(
                  <Input placeholder="audio" hidden />,
                )}
                {
                  this.state.uploadedFile
                    ? <a target="_blank" href={this.state.uploadedFile.location}>{this.state.uploadedFile.name}</a>
                    : (
                      <Upload
                        fileList={this.state.fileList}
                        defaultFileList={this.state.fileList}
                        onChange={this.onFileChange}
                        showUploadList={false}
                        beforeUpload={(file, fileList) => {
                          this.setState({ uploading: true });
                          this.props.toggleUploading(this.props.id, true);
                          S3Upload(file, this.props.dirName).then((data) => {
                            this.props.toggleUploading(this.props.id, false);
                            this.setState({ uploading: false });
                            this.props.form.setFieldsValue({ mp3Url: data.location });
                            this.setState({
                              uploadedFile: data,
                            });
                          }).catch((err) => {
                            console.error(err);
                            this.props.toggleUploading(this.props.id, false);
                          });
                          return false;
                        }}
                        accept="audio/*"
                        name="logo"
                      >
                        {
                          this.state.uploading ? <Spin /> : (
                            <Button>
                              <Icon type="upload" />
                              {' '}
                              Click to upload
                            </Button>
                          )
                        }
                        {/* {this.props.form.getFieldError('file')&&<p style={{color:'red'}}>You need to upload a file</p>} */}
                      </Upload>
                    )

                }

              </FormItem>
            </Col>
            <Col span={4}>
              <Button title="Remove Chapter" onClick={this.onClickRemove}><Icon type="close-circle" theme="twoTone" /></Button>
            </Col>

          </Row>


        </div>
      </Card>
    );
  }
}

const ChapterFormContainer = Form.create()(ChapterForm);

export default ChapterFormContainer;
