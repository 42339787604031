import React from "react";
import { message } from "antd";
import { navigate } from "@reach/router";
import CategoriesForm from "./form";
import API, { getAxiosInstance } from "../../api/api";
import PushNotificationsSendForm from "./form";
import PushNotificationsSendFormBulk from "./formBulk";

export class CreatePushNotification extends React.Component {
  state = {
    loading: false,
    bulkLoading: false,
  };
  bulkUsers = [];

  onSubmit = (formValue, reset) => {
    this.setState({ loading: true });

    getAxiosInstance()
      .post(API.create_push, formValue)
      .then((response) => {
        if (response.data.status) {
          this.setState({ loading: false });
          reset();
          message.success("Notification Sent");
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Oops, something went wrong :( ");
        this.setState({ loading: false });
      });
  };

  onSubmitBulk = (formValue, reset) => {
    this.setState({ bulkLoading: true });

    getAxiosInstance()
      .post(API.create_push_specific_users, formValue)
      .then((response) => {
        if (response.data.status) {
          this.setState({ loading: false });
          reset();
          message.success("Notification Sent");
        } else {
          console.error(response.data);
          message.error(response.data.dialog.message);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.error(err);
        message.error("Oops, something went wrong :( ");
        this.setState({ loading: false });
      });
  };

  render() {
    const loading = this.state.loading;
    const bulkLoading = this.state.bulkLoading;
    return (
      <div>
        <PushNotificationsSendForm loading={loading} onSubmit={this.onSubmit} />
        <PushNotificationsSendFormBulk
          loading={bulkLoading}
          onSubmit={this.onSubmitBulk}
        />
      </div>
    );
  }
}
